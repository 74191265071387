export const config = {

  // //// 本番用ドメイン
  // apiUrl: 'https://risk.prein.jp',
  // restUrl: 'https://risk.prein.jp/api/v1',
  // pmtilesUrl: 'https://d1fvo0r01dmjj6.cloudfront.net',

    //// 本番用ドメイン
    apiUrl: 'https://d3f195c88be910.cloudfront.net',
    restUrl: 'https://d3f195c88be910.cloudfront.net/api/v1',
    pmtilesUrl: 'https://d29yun90warqzr.cloudfront.net',
  
  // //// テスト用ドメイン
  // apiUrl: 'https://d18yfiehgxn0gs.cloudfront.net',
  // restUrl: 'https://d18yfiehgxn0gs.cloudfront.net/api/v1',
  // pmtilesUrl: 'https://d29yun90warqzr.cloudfront.net',

  // //// 開発用ドメイン
  // apiUrl: 'https://dzla9fu56dvi.cloudfront.net',
  // restUrl: 'https://dzla9fu56dvi.cloudfront.net/api/v1',
  // pmtilesUrl: 'https://d29yun90warqzr.cloudfront.net',

  accessibleId: [1, 2],
}
